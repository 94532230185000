import * as React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import Module from "../components/module"
import Stack from "../components/stack"
import Text from "../components/text"
import Heading from "../components/heading"
import Hero from "../components/hero"
import Container from "../components/container"

import TextContainer from "../components/textContainer"

const QUERY = graphql`
  {
    sanityHeroImage(name: { eq: "contact" }) {
      id
      image {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`

const Page = () => {
  const data = useStaticQuery(QUERY)
  return (
    <Layout>
      <Hero
        title="Work with us."
        image={data.sanityHeroImage.image.asset.gatsbyImageData}
      >
        <Heading as="h1" inverted>
          Contact Us
        </Heading>
      </Hero>
      <Module>
        <Container>
          <TextContainer>
            <Stack>
              <Heading as="h2">We’d love to hear from you.</Heading>
              <Text large>
                Give us a call to book an appointment, enquire about our
                services or apply for a position with us.
              </Text>
              <ContactDetails>
                <Text>PO Box 1484 Buderim QLD 4556</Text>
                <div>
                  <a href="mailto:info@q-medical.com.au">
                    <Text>E: info@q-medical.com.au</Text>
                  </a>
                </div>
              </ContactDetails>
            </Stack>
          </TextContainer>
        </Container>
      </Module>
    </Layout>
  )
}

const ContactDetails = styled.div`
  > * {
    border-bottom: 1px solid rgba(15, 36, 44, 0.15);
    padding: 1rem 0;
  }
`

export default Page
